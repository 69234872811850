import {
  SORT_ERROR_LOG,
  SORT_LATEST,
  SORT_OLDEST,
  ALL_CATEGORY,
  DEFAULT_SORT,
  DEFAULT_FILTERS,
  DEFAULT_CONTENT,
  DEFAULT_CATEGORY,
} from './constants';
import moment from 'moment';

const searchContent = (
  contents = DEFAULT_CONTENT,
  searchResults = DEFAULT_CONTENT,
  queryString = ''
) => {
  const hasEmptySearchBar = queryString === '';
  if (hasEmptySearchBar) return contents;

  const searchedContent = contents.filter((content) => {
    const itemExist = searchResults.find((result, idx) => {
      return result == content.id;
    });
    return itemExist;
  });
  return searchedContent;
};

const sortContent = (contents = DEFAULT_CONTENT, sort = DEFAULT_SORT) => {
  switch (sort) {
    case SORT_LATEST:
      return contents;
    case SORT_OLDEST:
      const immutableReversedContents = [...contents].reverse();
      return immutableReversedContents;
    default:
      console.error(SORT_ERROR_LOG);
      return DEFAULT_CONTENT;
  }
};

const categorizeContent = (
  contents = DEFAULT_CONTENT,
  category = DEFAULT_CATEGORY
) => {
  const isAllSelected = category === ALL_CATEGORY;
  if (isAllSelected) return contents;

  const categorizedContent = contents.filter(
    (content) => content?.category?.name === category
  );

  return categorizedContent;
};

const filterContentByAuthor = (contents = DEFAULT_CONTENT, author = '') => {
  if (!author) return contents;

  return contents.filter((content) => {
    const hasUserNameMatches = content?.author?.username === author;
    const hasCreatorIdMatches = content?.creator_id === author;
    return hasUserNameMatches || hasCreatorIdMatches;
  });
};

const filterContentByStartingMonth = (
  contents = DEFAULT_CONTENT,
  monthYear = ''
) => {
  const isMonthYearInvalid = !moment(monthYear, 'MMMM YYYY').isValid();
  if (isMonthYearInvalid) return contents;

  return contents.filter((content) => {
    const contentMonthYear = moment(content.start_date, 'YYYY-MM-DD').format(
      'MMMM YYYY'
    );
    return contentMonthYear === monthYear;
  });
};

const filterContents = (
  contents = DEFAULT_CONTENT,
  filters = DEFAULT_FILTERS
) => {
  const { search, query, sort, category, author } = filters;

  let filteredContent = contents;

  filteredContent = categorizeContent(filteredContent, category);
  filteredContent = sortContent(filteredContent, sort);
  filteredContent = searchContent(filteredContent, search, query);
  filteredContent = filterContentByAuthor(filteredContent, author);

  return filteredContent;
};

export default filterContents;

const filterEventsContents = (
  contents = DEFAULT_CONTENT,
  filters = DEFAULT_FILTERS
) => {
  const { search, query, start, author } = filters;

  let filteredContent = contents;

  filteredContent = searchContent(filteredContent, search, query);
  filteredContent = filterContentByStartingMonth(filteredContent, start);
  filteredContent = filterContentByAuthor(filteredContent, author);

  return filteredContent;
};

export { filterEventsContents };

const filterFaqsContents = (
  contents = DEFAULT_CONTENT,
  filters = DEFAULT_FILTERS
) => {
  const { search, query } = filters;

  let filteredFaqsContent = contents;
  filteredFaqsContent = searchContent(filteredFaqsContent, search, query);

  return filteredFaqsContent;
};

export { filterFaqsContents };
