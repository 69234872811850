import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const Icon = () => {
  return (
    <div className="empty-search-icon-container">
      <SpriteIconViewer className="empty-search-icon" spriteId="search" />
    </div>
  );
};

export default Icon;
