import React from 'react';
import Icon from './Icon';
import EmptyState from '@components/ArticleCardsViewer/EmptyState';

const NoResultEmptyState = (subMessage = '') => {
  return (
    <EmptyState
      Icon={<Icon />}
      mainMessage={'No results found'}
      subMessage={
        'Try adjusting your search by using different keywords or removing filters.'
      }
    />
  );
};

export default NoResultEmptyState;
